import { createStore } from "vuex";
import auth from "./modules/auth";
import global from "./modules/global";

const store = createStore({
  strict: true,
  modules: {
    auth,
    global,
  },
  state: {},
  mutations: {},
  actions: {},
  plugins: [],
});

export default store;
