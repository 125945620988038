const globals = {
  state: {
    message: null,
    loading: false,
    snackbar: false,
    snackbarText: null,
    examTimeInfo: {
      examHours: "00",
      examMins: "00",
      examSeconds: "00",
      progressBarWidth: "100%",
      timer1: 0,
    },
    consumedTimeInfo: 0,
  },
  mutations: {
    SET_GLOBAL_MESSAGE: (state, message) => {
      state.message = message;
    },
    SET_LOADING: (state, loading) => {
      state.loading = loading;
    },
    SET_SNACKBAR: (state, snackbar) => {
      state.snackbar = snackbar;
    },
    SET_SNACKBAR_TEXT: (state, snackbarText) => {
      state.snackbarText = snackbarText;
    },
    SET_EXAM_TIME: (state, examTimeInfo) => {
      state.examTimeInfo = examTimeInfo;
    },
    SET_CONSUMED_TIME: (state, consumedTimeInfo) => {
      state.consumedTimeInfo = consumedTimeInfo;
    },
  },
  actions: {
    setGlobalMessage({ commit }, global) {
      commit("SET_GLOBAL_MESSAGE", global.message);
    },
    setLoading({ commit }, global) {
      commit("SET_LOADING", global.loading);
    },
    setSnackbar({ commit }, global) {
      commit("SET_SNACKBAR", global.snackbar);
    },
    setSnackbarText({ commit }, global) {
      commit("SET_SNACKBAR_TEXT", global.snackbarText);
    },
    setExamTimeInfo({ commit }, global) {
      commit("SET_EXAM_TIME", global);
    },
    setConsumedTimeInfo({ commit }, global) {
      commit("SET_CONSUMED_TIME", global);
    },
  },
  getters: {
    global: (state) => state,
  },
};
// if (JSON.parse(sessionStorage.getItem("examInfo"))) {
//   let examHr = JSON.parse(sessionStorage.getItem("examInfo")).durationHrs;
//   let examMin = JSON.parse(sessionStorage.getItem("examInfo")).durationMin;
//   examHr = examHr.toString();
//   examMin = examMin.toString();
//   examHr = examHr.length == 1 ? "0" + examHr : examHr;
//   examMin = examMin.length == 1 ? "0" + examMin : examMin;
//   globals.state.examTimeInfo.examHours = examHr;
//   globals.state.examTimeInfo.examHours = examMin;
// }

export default globals;
