import Axios from "axios";
import store from "../store";
import router from "../router";

// axios
const axios = Axios.create({
  // CORS
  withCredentials: true,
});

axios.defaults.headers.common["qmall-api-token"] = localStorage.getItem(
  "qmallUserLoginToken"
);
axios.defaults.headers.common["qmall-app-id"] = 2;
axios.defaults.headers.common["user-time-zone"] = JSON.parse(
  localStorage.getItem("user_details")
)
  ? JSON.parse(localStorage.getItem("user_details")).timezone
  : "";

axios.interceptors.request.use((config) => {
  config.timeout = 30000;
  if (config && config.headers) {
    if (config.headers.common) {
      config.headers.common["qmall-api-token"] = localStorage.getItem(
        "qmallUserLoginToken"
      );
      config.headers.common["user-time-zone"] = JSON.parse(
        localStorage.getItem("user_details")
      )
        ? JSON.parse(localStorage.getItem("user_details")).timezone
        : "";
    } else {
      config.headers["qmall-api-token"] = localStorage.getItem(
        "qmallUserLoginToken"
      );
      config.headers["user-time-zone"] = JSON.parse(
        localStorage.getItem("user_details")
      )
        ? JSON.parse(localStorage.getItem("user_details")).timezone
        : "";
    }
  }
  // console.log(config);

  return config;
});

axios.interceptors.request.use((request) => {
  store.dispatch("setLoading", {
    loading: true,
  });

  // if (process.env.NODE_ENV === "development") {
  //   console.log(request);
  // }
  return request;
});

axios.interceptors.response.use(
  (response) => {
    store.dispatch("setLoading", {
      loading: false,
    });
    // if (process.env.NODE_ENV === "development") {
    //   console.log(response);
    // }
    if (response.headers["qmall-api-token"] !== undefined) {
      if (response.status === 200) {
        store.dispatch("setLoginInfo", {
          token: response.headers["qmall-api-token"],
        });
        localStorage.setItem(
          "qmallUserLoginToken",
          response.headers["qmall-api-token"]
        );
      }
    }
    if (response.data && response.data.code) {
      store.dispatch("setGlobalMessage", {
        message: {
          code: response.data.code,
          message: response.data.message,
        },
      });
    }
    return response;
  },
  (error) => {
    store.dispatch("setLoading", {
      loading: false,
    });
    // if (process.env.NODE_ENV === "development") {
    //   if (error.response) {
    //     console.log(error.response);
    //   } else {
    //     console.log(error);
    //   }
    // }

    if (error.code === "ECONNABORTED") {
      store.dispatch("setGlobalMessage", {
        message: {
          code: null,
          message: "An API timeout has occurred.",
        },
      });
      return { status: 500 };
    } else if (!error.response) {
      store.dispatch("setGlobalMessage", {
        message: {
          code: null,
          message: "A network error has occurred.",
        },
      });
      return { status: 500 };
    }

    if (error.response.status === 400) {
      store.dispatch("setGlobalMessage", {
        message: {
          code: error.response.data ? error.response.data.code : null,
          message: error.response.data.message,
        },
      });
    } else if (error.response.status === 401) {
      store.dispatch("setGlobalMessage", {
        message: {
          code: error.response.data.code,
          message: error.response.data.message,
        },
      });
      const selectedLanguage = localStorage.getItem("lang");
      localStorage.clear();
      localStorage.setItem("lang", selectedLanguage);
      sessionStorage.clear();

      router.push({
        path: "/login",
        query: { redirect: router.currentRoute.fullPath },
      });
    } else if (error.response.status === 404) {
      store.dispatch("setGlobalMessage", {
        message: {
          code: null,
          message: "An API error has occurred.\n(http_status: 404)",
        },
      });
    } else if (error.response.status === 500) {
      let message;
      if (typeof error.response.data.message === "undefined") {
        message = "An API error has occurred.\n(http_status: 500)";
      } else {
        message = error.response.data.message;
      }
      store.dispatch("setGlobalMessage", {
        message: {
          code: null,
          message: message,
        },
      });
    }

    return error.response;
  }
);

// axios.interceptors.response.use((response) => {
//   console.log(response);
//   return response;
// });

export default axios;
