const auth = {
  state: {
    login: {
      token: null,
    },
    user_role: null,
    user_details: null,
  },
  mutations: {
    SET_LOGIN_INFO: (state, login) => {
      state.login.token = login.token;
    },
    SET_USER_ROLE_INFO: (state, user_role) => {
      state.user_role = user_role;
    },
    SET_USER_DETAILS_INFO: (state, user_details) => {
      state.user_details = user_details;
    },
  },
  actions: {
    setLoginInfo({ commit }, login) {
      commit("SET_LOGIN_INFO", login);
    },
    setHrInfo({ commit }, user_role) {
      commit("SET_USER_ROLE_INFO", user_role);
    },
    setAdminInfo({ commit }, user_details) {
      commit("SET_USER_DETAILS_INFO", user_details);
    },
  },
  getters: {
    login: (state) => state.login,
    user_role: (state) => state.user_role,
    user_details: (state) => state.user_details,
  },
};

export default auth;
